
jQuery(document).ready(function($) {
 
	$('.testimonial').slick({
		autoplay: true,
  		autoplaySpeed: 8000,
  		speed: 1900,
		dots: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		responsive: [
		{
		  breakpoint: 481,
		  settings: {
		  	slidesToShow: 1,
			slidesToScroll: 1,
			dots: false
		  }
		}
	  ]
	});
 
});
				