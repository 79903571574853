/**
 * JavaScript controls for various features of the site,
 * including that required by the components stored in
 * template parts in ../../parts.
 *
 *
 */

var $ = jQuery.noConflict();

/**
 *
 * Move skip links focus on Chrome and IE9.
 *
 * @since 1.0.0
 */
window.addEventListener( 'hashchange', function( event ) {
	var element = document.getElementById( location.hash.substring( 1 ) );

	if ( element ) {
		if ( ! /^(?:a|select|input|button|textarea)$/i.test( element.tagName ) ) {
			element.tabIndex = -1;
		}
		element.focus();
	}
}, false );

// Override addClass() to trigger a cssClassChanged event. We need this for detecting focus class applied by Adobe Accessible Mega Menu.
( function() {
	// The original method.
	var originalAddClassMethod = jQuery.fn.addClass;

	jQuery.fn.addClass = function() {
		// Execute the original method.
		var result = originalAddClassMethod.apply( this, arguments );

		// trigger a custom event
		jQuery(this).trigger( 'cssClassChanged' );

		// return the original result
		return result;
	}
})();

$( document ).ready( function() {

	/**
	 * Accessible menus.
	 *
	 * Includes facility for "mega menu" style dropdowns via classes added in the WordPress admin.
	 * With full keyboard navigation.
	 *
	 * @since 1.0.0
	 */

		// First add .sub-nav <div>s around .sub-menu <ul>s.
	$( '.menu-primary > .menu-item-has-children > ul.sub-menu' ).wrap( '<div class="sub-menu-panel"></div>' );
	$( '.menu-secondary > .menu-item-has-children > ul.sub-menu' ).wrap( '<div class="sub-menu-panel"></div>' );

	// If mega menu CSS classes are detected, add wraps for the columns.
	for ( var menu = 1; menu < 11; menu++ ) {
		if ( $( '.menu-primary > .mega-menu-' + menu ) ) {
			for ( var col = 1; col < 5; col++ ) {
				if ( $( '.menu-primary > .mega-menu-' + menu + ' .sub-menu-column-' + col ) ) {
					$( '.menu .mega-menu-' + menu + ' .sub-menu-column-' + col ).wrapAll( '<div class="sub-menu-column"></div>' );
				}
			}
		}
	}

	var megaMenu = $( 'li[class*="mega-menu-"]' );
	var megaPanel = $( 'li[class*="mega-menu-"] .sub-menu-panel' );
	var megaPanelSubMenu = $( 'li[class*="mega-menu-"] .sub-menu-panel .sub-menu' );
	var megaPanelWidth = parseInt( $( document ).width(), 10 );
	if ( megaPanelWidth > 1350 ) {
		megaPanelWidth = 1350;
	}
	var megaPanelMargin = parseInt( ( $( document ).width() - megaPanelWidth ) / 2, 10 );
	megaPanel.css( 'width', megaPanelWidth );

	megaMenu.on( 'cssClassChanged', function() {
		shiftMegaPanel( $( this ) );
	});

	megaPanel.on( 'cssClassChanged', function() {
		shiftMegaPanel( $( this ) );
	});

	megaPanelSubMenu.on( 'cssClassChanged', function() {
		shiftMegaPanel( $( this ) );
	});

	function shiftMegaPanel( descendent ) {
		var currentPanel = descendent.closest( '.sub-menu-panel' );
		if ( currentPanel.hasClass( 'open' ) ) {
			var megaPanelOffset = parseInt( currentPanel.offset().left, 10 )
			if (  megaPanelOffset > megaPanelMargin ) {
				currentPanel.css( 'left', 0 - ( megaPanelOffset - megaPanelMargin ) );
			}
		}
	}

	/**
	 * Initiate our accessible primary menu.
	 *
	 * @since 1.0.0
	 */

	$( '.nav-primary' ).accessibleMegaMenu({

		// Prefix for generated unique id attributes, which are required to indicate aria-owns, aria-controls and aria-labelledby.
		uuidPrefix: 'accessible-primary-nav',

		// CSS class used to define the megamenu styling.
		menuClass: 'menu-primary',

		// CSS class for a top-level navigation item in the megamenu.
		topNavItemClass: 'nav-item',

		// CSS class for a megamenu panel (Genesis doesn't have this so we've added it via JS above).
		panelClass: 'sub-menu-panel',

		// CSS class for a group of items within a megamenu panel.
		panelGroupClass: 'sub-menu',

		// CSS class for the hover state.
		hoverClass: 'hover',

		// CSS class for the focus state.
		focusClass: 'focus',

		// CSS class for the open state.
		openClass: 'open'
	});

	/**
	 * Initiate our accessible secondary menu.
	 *
	 * @since 1.0.0
	 */

	$( '.nav-secondary' ).accessibleMegaMenu({

		// Prefix for generated unique id attributes, which are required to indicate aria-owns, aria-controls and aria-labelledby.
		uuidPrefix: 'accessible-secondary-nav',

		// CSS class used to define the megamenu styling.
		menuClass: 'menu-secondary',

		// CSS class for a top-level navigation item in the megamenu.
		topNavItemClass: 'nav-item',

		// CSS class for a megamenu panel (Genesis doesn't have this so we've added it via JS above).
		panelClass: 'sub-menu-panel',

		// CSS class for a group of items within a megamenu panel.
		panelGroupClass: 'sub-menu',

		// CSS class for the hover state.
		hoverClass: 'hover',

		// CSS class for the focus state.
		focusClass: 'focus',

		// CSS class for the open state.
		openClass: 'open'
	});
});

/**
 * Resize front page header block to window size.
 *
 * Uncomment and update the selector to make an element exactly fit
 * the current browser viewport.
 *
 * @since  1.0.0
 */
// ( function() {
// 	var resizeWindow = function() {
// 		if ( $( '.front-page .site-header' ).height() < 343 ) {
// 			$( '.front-page .site-header' ).height( 343 );
// 		} else {
// 			$( '.front-page .site-header' ).height( $( this ).height() );
// 		}
// 	};
// 	resizeWindow();
// 	$( window ).on( 'resize', resizeWindow );
// })();

/**
 * Scroll on page links
 */
( function( $ ) {
	$.mark = {
		jump: function( options ) {
			var defaults = {
				selector: 'a.scroll-on-page-link'
			};
			if ( 'string' === typeof options ) {
				defaults.selector = options;
			}

			options = $.extend( defaults, options );
			return $( options.selector ).click( function( e ) {
				var jumpobj = $( this );
				var target = jumpobj.attr( 'href' );
				var thespeed = 1250;
				var offset = $( target ).offset().top;
				$( 'html,body' ).animate({
					scrollTop: offset
				}, thespeed, 'swing' );
				e.preventDefault();
			});
		}
	};
})( $ );

$( function() {
	$.mark.jump();
});

/**
 * Parallax.
 *
 * @since 1.0.0
 */
$( document ).ready( function() {
	if ( $( '#js-parallax-window' ).length ) {
		parallax();
	}
});

$( window ).scroll(function( e ) {
	if ( $( '#js-parallax-window' ).length ) {
		parallax();
	}
});

function parallax() {
	if ( $( '#js-parallax-window' ).length > 0 ) {
		var plxBackground = $( '#js-parallax-background' );
		var plxWindow = $( '#js-parallax-window' );

		var plxWindowTopToPageTop = $( plxWindow ).offset().top;
		var windowTopToPageTop = $( window ).scrollTop();
		var plxWindowTopToWindowTop = plxWindowTopToPageTop - windowTopToPageTop;

		var plxBackgroundTopToPageTop = $( plxBackground ).offset().top;
		var windowInnerHeight = window.innerHeight;
		var plxBackgroundTopToWindowTop = plxBackgroundTopToPageTop - windowTopToPageTop;
		var plxBackgroundTopToWindowBottom = windowInnerHeight - plxBackgroundTopToWindowTop;
		var plxSpeed = 0.35;

		plxBackground.css( 'top', -( plxWindowTopToWindowTop * plxSpeed ) + 'px' );
	}
}

/**
 * Off-canvas navigation.
 *
 * Our slide-in navigation for small screens.
 *
 * @since 1.0.0
 */
$( document ).ready( function() {
	$( 'sliding-panel-content' ).attr( 'aria-hidden', 'true' );
	$( '.sliding-panel-button,.sliding-panel-fade-screen,.sliding-panel-close' ).on( 'click touchstart', function( e ) {
		$( '.sliding-panel-content,.sliding-panel-fade-screen' ).toggleClass( 'is-visible' );
		var $expandedState = $( '.sliding-panel-button' );
		$expandedState.attr( 'aria-expanded',
			$expandedState.attr( 'aria-expanded' ) === 'false' ? 'true' : 'false'
		);
		e.preventDefault();
	});
});

/**
 * Normal tabs.
 *
 * Tabs with a bit of styling.
 *
 * @since 1.0.0
 */
$( document ).ready( function() {
	$( '.accordion-tabs' ).each(function( index ) {
		$( this ).children( 'li' ).first().children( 'a' ).addClass( 'is-active' ).next().addClass( 'is-open' ).show();
	});
	$( '.accordion-tabs' ).on( 'click', 'li > a.tab-link', function( event ) {
		if ( ! $( this ).hasClass( 'is-active' ) ) {
			event.preventDefault();
			var accordionTabs = $( this ).closest( '.accordion-tabs' );
			accordionTabs.find( '.is-open' ).removeClass( 'is-open' ).hide();

			$( this ).next().toggleClass( 'is-open' ).toggle();
			accordionTabs.find( '.is-active' ).removeClass( 'is-active' );
			$( this ).addClass( 'is-active' );
		} else {
			event.preventDefault();
		}
	});
});

/**
 * Minimal tabs.
 *
 * Tabs with the minimum amount of styling.
 *
 * @since 1.0.0
 */
$( document ).ready( function() {
	$( '.accordion-tabs-minimal' ).each( function( index ) {
		$( this ).children( 'li' ).first().children( 'a' ).addClass( 'is-active' ).next().addClass( 'is-open' ).show();
	});

	$( '.accordion-tabs-minimal' ).on( 'click', 'li > a', function( event ) {
		if ( ! $( this ).hasClass( 'is-active' ) ) {
			event.preventDefault();
			var accordionTabs = $( this ).closest( '.accordion-tabs-minimal' );
			accordionTabs.find( '.is-open' ).removeClass( 'is-open' ).hide();

			$( this ).next().toggleClass( 'is-open' ).toggle();
			accordionTabs.find( '.is-active' ).removeClass( 'is-active' );
			$( this ).addClass( 'is-active' );
		} else {
			event.preventDefault();
		}
	});
});

/**
 * Vertical tabs.
 *
 * Vertically oriented tabs.
 *
 * @since 1.0.0
 */
$( document ).ready( function() {
	$( '.js-vertical-tab-content' ).hide();
	$( '.js-vertical-tab-content:first' ).show();

	// If in tab mode.
	$( '.js-vertical-tab' ).click( function( event ) {
		event.preventDefault();

		$( '.js-vertical-tab-content' ).hide();
		var activeTab = $( this ).attr( 'rel' );
		$( '#' + activeTab ).show();

		$( '.js-vertical-tab' ).removeClass( 'is-active' );
		$( this ).addClass( 'is-active' );

		$( '.js-vertical-tab-accordion-heading' ).removeClass( 'is-active' );
		$( '.js-vertical-tab-accordion-heading[rel^="' + activeTab + '"]' ).addClass( 'is-active' );
	});

	// If in accordion mode.
	$( '.js-vertical-tab-accordion-heading' ).click( function( event ) {
		event.preventDefault();

		$( '.js-vertical-tab-content' ).hide();
		var accordion_activeTab = $( this ).attr( 'rel' );
		$( '#' + accordion_activeTab ).show();

		$( '.js-vertical-tab-accordion-heading' ).removeClass( 'is-active' );
		$( this ).addClass( 'is-active' );

		$( '.js-vertical-tab' ).removeClass( 'is-active' );
		$( '.js-vertical-tab[rel^="' + accordion_activeTab + '"]' ).addClass( 'is-active' );
	});
});

/**
 * Expanders
 *
 * @since 1.0.0
 */
$( document ).ready( function() {
	$( 'a[class*="js-expander-trigger"]' ).click( function() {
		$( this ).toggleClass( 'expander-hidden' );
	});
});

/**
 * Modals.
 *
 * A pop-up modal window that appears when an element is clicked.
 *
 * @since 1.0.0
 */
$( function() {
	$( '#modal-1' ).on( 'change', function() {
		if ( $( this ).is( ':checked' ) ) {
			$( 'body' ).addClass( 'modal-open' );
		} else {
			$( 'body' ).removeClass( 'modal-open' );
		}
	});

	$( '.modal-fade-screen, .modal-close' ).on( 'click', function() {
		$( '.modal-state:checked' ).prop( 'checked', false ).change();
	});

	$( '.modal-inner' ).on( 'click', function( e ) {
		e.stopPropagation();
	});
});

/**
 * Auto show the modal on certain pages only.
 *
 * Uncomment the below function if you want the modal to appear
 * automatically when a page is loaded (rather than when an
 * element is clicke).
 *
 * Update the target body class as required.
 *
 * @since 1.0.0
 */
// $( document ).ready( function() {
//   if ( $( 'body' ).hasClass( 'front-page' ) ) {
//     $( '.modal-state' ).prop( 'checked', true ).change();
//   }
// });

/**
 * Auto show the modal on certain pages only and depending on cookie setting.
 *
 * Uncomment the below function if you're using the auto-appear
 * function above but only want the modal to appear the first
 * time a visitor hits the page.
 *
 * Sets a cookie. Update the cookie name and expiration date to suit.
 *
 * @since  1.0.0
 */
// $( document ).ready( function() {
//   if( $( 'body' ).hasClass( 'front-page' ) ) {
//     if ( Cookies.get( 'pilot-modal' ) !== 'true' ) {
//       Cookies.set( 'pilot-modal', 'true', { expires: 7, path: '/' } );
//       $( '.modal-state' ).prop( 'checked', true ).change();
//     }
//   }
// });


jQuery(function( $ ){

	$('#main-nav-search-link').click(function(){
		$('.search-div').show('slow');
	});

	$("*", document.body).click(function(event){
		// event.stopPropagation();
		var el = $(event.target);
		var gsfrm = $(el).closest('form');
		if(el.attr('id') !='main-nav-search-link' && el.attr('role') != 'search' && gsfrm.attr('role') != 'search'){
			$('.search-div').hide('slow');
		}
	});

});